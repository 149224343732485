// import BlacklistIconSvg from '@/assets/icons/blacklist-icon';
import ChannelsIconSvg from '@/assets/icons/channels-icon';
import ChatGPTIconSvg from '@/assets/icons/chatIcon';
import CustomKeywordsSvg from '@/assets/icons/custom-keywords.svg';
import DashboardIconSvg from '@/assets/icons/dashboard-icon';
import KeywordsIconSvg from '@/assets/icons/keywords-icon';
import RpmPredictorIconSvg from '@/assets/icons/rpm-predictor-icon';
import SavedIconSvg from '@/assets/icons/saved-icon';
import RoutesConfig from '@/config/routes.config';
import { ActiveMenuItem } from '../../sidebar.config';
import { MenuItems } from './constants';

const navItems: MenuItems[] = [
  {
    id: 'ai-niche-finder',
    Icon: ChannelsIconSvg,
    label: 'AI Niche Finder',
    link: RoutesConfig.HOME,
    activeItem: ActiveMenuItem.HOME,
  },
  {
    id: 'dashboard',
    Icon: DashboardIconSvg,
    label: 'Dashboard',
    link: RoutesConfig.NEXLEV_DASHBOARD,
    activeItem: ActiveMenuItem.DASHBOARD,
  },
  {
    id: 'keywords',
    Icon: KeywordsIconSvg,
    label: 'Keywords',
    link: RoutesConfig.KEYWORDS,
    activeItem: ActiveMenuItem.KEYWORDS,
  },
  {
    id: 'custom-keywords',
    Icon: CustomKeywordsSvg,
    label: 'Custom Keywords',
    link: RoutesConfig.CUSTOM_KEYWORDS,
    activeItem: ActiveMenuItem.CUSTOM_KEYWORDS,
  },
  {
    id: 'channels',
    Icon: ChannelsIconSvg,
    label: 'Channels',
    link: RoutesConfig.CHANNELS,
    activeItem: ActiveMenuItem.CHANNELS,
  },

  {
    id: 'saved',
    Icon: SavedIconSvg,
    label: 'Saved',
    link: RoutesConfig.SAVED,
    activeItem: ActiveMenuItem.SAVED,
  },
  {
    id: 'rpm-predictor',
    Icon: RpmPredictorIconSvg,
    label: 'RPM Predictor',
    link: RoutesConfig.RPM,
    activeItem: ActiveMenuItem.RPM_PREDICTOR,
  },
  // {
  //   Icon: BlacklistIconSvg,
  //   label: 'Black List',
  //   link: RoutesConfig.BLACKLIST,
  // },
  // {
  //   Icon: ChatGPTIconSvg,
  //   label: 'NexLev AI',
  //   link: RoutesConfig.CHATGPT,
  // },
  {
    id: 'nexlev-ai',
    Icon: ChatGPTIconSvg,
    label: 'NexLev AI',
    link: RoutesConfig.CHATGPT,
    activeItem: ActiveMenuItem.CHATGPT,
  },
];

export default navItems;
