interface IconProps {
  stroke?: string;
  height?: number;
  width?: number;
  props?: string;
}

const ChatIcon = ({
  stroke = '#161313',
  height = 20,
  width = 20,
  props,
}: IconProps) => {
  return (
    <svg
      className={props}
      width={width}
      height={height}
      viewBox="0 0 22 22"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M18.0156 0.015625C19.0938 0.015625 19.9844 0.90625 19.9844 1.98438V13.9844C19.9844 15.1094 19.0938 16 18.0156 16H4L0.015625 19.9844V1.98438C0.015625 0.90625 0.90625 0.015625 1.98438 0.015625H18.0156ZM18.0156 13.9844V1.98438H1.98438V16L4 13.9844H18.0156ZM4.98438 7V9.01562H7V7H4.98438ZM9.01562 7V9.01562H10.9844V7H9.01562ZM13 7V9.01562H15.0156V7H13Z"
        fill={stroke}
      />
    </svg>
  );
};
export default ChatIcon;
