import * as Sentry from '@sentry/react';
import { ElementType, lazy, Suspense, useEffect } from 'react';
import { Route, Routes, useLocation } from 'react-router-dom';

import SuspenseLoader from '@/components/suspense-loader';
import routesConfig from '@/config/routes.config';
import NavbarLayout from '@/layouts/navbar.layout';
import SidebarLayout from '@/layouts/sidebar/sidebar.layout';
import PaymentFailedModal from './components/modals/payment-failed-modal.component';
import PaymentSuccessModal from './components/modals/payment-success-modal.component';
import AdminLayout from './layouts/admin/admin.layout';
import AuthenticationLayout from './layouts/authentication.layout';
import BlurredAuthWrapper from './wrapper/blurred-auth.wrapper';
import PrivateWrapper from './wrapper/private.wrapper';
import PublicWrapper from './wrapper/public.wrapper';
import SubscriptionWrapper from './wrapper/subscription.wrapper';

const SentryRoutes = Sentry.withSentryReactRouterV6Routing(Routes);

const Loader = (Component: ElementType) => (props: any) =>
  (
    <Suspense fallback={<SuspenseLoader />}>
      <Component {...props} />
    </Suspense>
  );

const Home = Loader(lazy(() => import('@/pages/highlighted-channels.page')));
const NexLevDashboard = Loader(lazy(() => import('@/pages/home.page')));
const LogIn = Loader(lazy(() => import('@/pages/login.page')));
const SavedPage = Loader(lazy(() => import('@/pages/saved.page')));
const PageNotFound = Loader(lazy(() => import('@/pages/404.page')));
const CreditPage = Loader(lazy(() => import('@/pages/credit.page')));
const Blacklist = Loader(lazy(() => import('@/pages/blacklist.page')));
const ForgotPass = Loader(lazy(() => import('@/pages/forgot-pass.page')));
const KeywordList = Loader(lazy(() => import('@/pages/keyword-list.page')));
const ChannelList = Loader(lazy(() => import('@/pages/channel-list.page')));
const Registration = Loader(lazy(() => import('@/pages/registration.page')));
const RPMPredictor = Loader(lazy(() => import('@/pages/rpm-predictor.page')));
const ConfirmUserPage = Loader(lazy(() => import('@/pages/confirm-user.page')));
const ChatGPT = Loader(lazy(() => import('@/pages/chat-gpt.page')));
const AddHighlightedChannels = Loader(
  lazy(() => import('@/pages/highlighted-channels-add.page'))
);

const CustomKeywordsPage = Loader(
  lazy(() => import('@/pages/custom-keywords.page'))
);

const Subscription = Loader(
  lazy(() => import('@/pages/subscription-plan.page'))
);

const ProfileSetting = Loader(
  lazy(() => import('@/pages/profile-setting.page'))
);
const NexlevAdmin = Loader(lazy(() => import('@/pages/admin-panel.page')));
const SetNewPassword = Loader(
  lazy(() => import('@/pages/set-new-password.page'))
);
const ChannelFilterTemplatePage = Loader(
  lazy(() => import('@/pages/channel-filter-template.page'))
);

const KeywordsFilterTemplatePage = Loader(
  lazy(() => import('@/pages/keyword-filter-template.page'))
);

interface IPageTitles {
  [key: string]: string;
}

export const pageTitles: IPageTitles = {
  [routesConfig.HOME]: 'Home | AI Niche Finder',
  [routesConfig.NEXLEV_DASHBOARD]: 'NexLev Dashboard',
  [routesConfig.SAVED]: 'Saved ',
  [routesConfig.LOGIN]: 'Log In',
  [routesConfig.KEYWORDS]: 'Keywords',
  [routesConfig.RPM]: 'RPM Predictor',
  [routesConfig.CHANNELS]: 'Channels',
  [routesConfig.HIGHLIGHTED_CHANNELS]: 'Highlighted Channels',
  [routesConfig.ADD_HIGHLIGHTED_CHANNELS]: 'Highlighted Channels Add',
  [routesConfig.BLACKLIST]: 'Black List',
  '/blacklist-378c8510-f4db-11ed-a05b-0242ac120003': 'Black List',
  [routesConfig.CHATGPT]: 'NexLev AI',
  '/nexlev-ai-378c8510-f4db-11ed-a05b-0242ac120003': 'NexLev AI',
  [routesConfig.BUY_CREDITS]: 'Buy Credits',
  [routesConfig.CONFIRM_USER]: 'Confirm User',
  [routesConfig.REGISTRATION]: 'Registration',
  '/378c8510-f4db-11ed-a05b-0242ac120003': 'Registration',
  [routesConfig.SUBSCRIPTION]: 'Subscription',
  [routesConfig.PAYMENT_FAILED]: 'Payment Failed',
  [routesConfig.FORGOT_PASSWORD]: 'Forgot Password?',
  [routesConfig.SET_NEW_PASSWORD]: 'Set New Password?',
  [routesConfig.CUSTOM_KEYWORDS]: 'Custom Keywords',
  [routesConfig.PAYMENT_SUCCESS]: 'Payment Success',
  [routesConfig.PROFILE_SETTING]: 'Profile Setting',
  [routesConfig.NEXLEV_ADMIN]: 'Admin',
  [routesConfig.KEYWORDS_TEMPLATE]: 'Keywords Filter Templates',
  [routesConfig.CHANNELS_TEMPLATE]: 'Channels Filter Templates',
};

const AppRoutes = () => {
  const location = useLocation();

  useEffect(() => {
    document.title = pageTitles[location.pathname] ?? '404';
  }, [location]);

  return (
    <SentryRoutes>
      <Route element={<AuthenticationLayout />}>
        <Route element={<PublicWrapper />}>
          <Route path={routesConfig.REGISTRATION} element={<Registration />} />
          <Route
            path="378c8510-f4db-11ed-a05b-0242ac120003"
            element={<Registration />}
          />
          <Route path={routesConfig.LOGIN} element={<LogIn />} />
          <Route
            path={routesConfig.CONFIRM_USER}
            element={<ConfirmUserPage />}
          />
          <Route path={routesConfig.FORGOT_PASSWORD} element={<ForgotPass />} />
          <Route
            path={routesConfig.SET_NEW_PASSWORD}
            element={<SetNewPassword />}
          />
        </Route>
      </Route>

      <Route element={<PrivateWrapper />}>
        <Route element={<SidebarLayout />}>
          <Route element={<NavbarLayout />}>
            <Route element={<SubscriptionWrapper />}>
              <Route
                path={routesConfig.HOME}
                element={
                  <BlurredAuthWrapper roles={['basic', 'pro', 'advanced']}>
                    <Home />
                  </BlurredAuthWrapper>
                }
              >
                <Route
                  path={routesConfig.PAYMENT_SUCCESS}
                  element={<PaymentSuccessModal />}
                />
                <Route
                  path={routesConfig.PAYMENT_FAILED}
                  element={<PaymentFailedModal />}
                />
              </Route>
              <Route
                path={routesConfig.NEXLEV_DASHBOARD}
                element={
                  <BlurredAuthWrapper roles={['basic', 'pro', 'advanced']}>
                    <NexLevDashboard />
                  </BlurredAuthWrapper>
                }
              />

              <Route
                path={routesConfig.RPM}
                element={
                  <BlurredAuthWrapper roles={['pro', 'advanced']}>
                    <RPMPredictor />
                  </BlurredAuthWrapper>
                }
              />
              <Route
                path={routesConfig.KEYWORDS}
                element={
                  <BlurredAuthWrapper roles={['basic', 'pro', 'advanced']}>
                    <KeywordList />
                  </BlurredAuthWrapper>
                }
              />
              <Route
                element={
                  <BlurredAuthWrapper roles={['basic', 'pro', 'advanced']}>
                    <KeywordsFilterTemplatePage />
                  </BlurredAuthWrapper>
                }
                path={routesConfig.KEYWORDS_TEMPLATE}
              />

              <Route
                path={routesConfig.CHANNELS}
                element={
                  <BlurredAuthWrapper roles={['basic', 'pro', 'advanced']}>
                    <ChannelList />
                  </BlurredAuthWrapper>
                }
              />
              <Route
                element={
                  <BlurredAuthWrapper roles={['basic', 'pro', 'advanced']}>
                    <ChannelFilterTemplatePage />
                  </BlurredAuthWrapper>
                }
                path={routesConfig.CHANNELS_TEMPLATE}
              />
              <Route
                path={routesConfig.SAVED}
                element={
                  <BlurredAuthWrapper roles={['basic', 'pro', 'advanced']}>
                    <SavedPage />
                  </BlurredAuthWrapper>
                }
              />
              <Route
                // path={routesConfig.BLACKLIST}
                path="/blacklist-378c8510-f4db-11ed-a05b-0242ac120003"
                element={
                  <BlurredAuthWrapper roles={['basic', 'pro', 'advanced']}>
                    <Blacklist />
                  </BlurredAuthWrapper>
                }
              />
              <Route
                path={routesConfig.CHATGPT}
                element={
                  <BlurredAuthWrapper roles={['pro', 'advanced']}>
                    <ChatGPT />
                  </BlurredAuthWrapper>
                }
              />

              <Route
                path={routesConfig.CUSTOM_KEYWORDS}
                element={
                  <BlurredAuthWrapper roles={['pro', 'advanced']}>
                    <CustomKeywordsPage />
                  </BlurredAuthWrapper>
                }
              />
              <Route path="*" element={<PageNotFound />} />
            </Route>

            <Route
              path={routesConfig.PROFILE_SETTING}
              element={<ProfileSetting />}
            />

            <Route path={routesConfig.BUY_CREDITS} element={<CreditPage />} />
          </Route>
          <Route element={<SubscriptionWrapper />}>
            <Route
              path={routesConfig.ADD_HIGHLIGHTED_CHANNELS}
              element={
                <BlurredAuthWrapper roles={['basic', 'pro', 'advanced']}>
                  <AddHighlightedChannels />
                </BlurredAuthWrapper>
              }
            />
          </Route>
        </Route>
        <Route path={routesConfig.SUBSCRIPTION} element={<Subscription />} />
        <Route element={<AdminLayout />}>
          <Route path={routesConfig.NEXLEV_ADMIN} element={<NexlevAdmin />} />
        </Route>
      </Route>
    </SentryRoutes>
  );
};

export default AppRoutes;
