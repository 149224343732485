import RoutesConfig from '@/config/routes.config';

type SidebarExpandedStateConfig = {
  [route: string]: boolean;
};

export enum ActiveMenuItem {
  HOME,
  DASHBOARD,
  KEYWORDS,
  CUSTOM_KEYWORDS,
  CHANNELS,
  SAVED,
  RPM_PREDICTOR,
  CHATGPT,
}

class SidebarConfig {
  private static sidebarExpandedStateConfig: SidebarExpandedStateConfig = {
    [RoutesConfig.HOME]: false,
    [RoutesConfig.NEXLEV_DASHBOARD]: true,
    [RoutesConfig.CHANNELS]: false,
    [RoutesConfig.CHANNELS_TEMPLATE]: false,
    [RoutesConfig.KEYWORDS_TEMPLATE]: false,
    [RoutesConfig.KEYWORDS]: false,
    [RoutesConfig.RPM]: true,
    [RoutesConfig.SAVED]: true,
    [RoutesConfig.CHATGPT]: false,
    [RoutesConfig.CUSTOM_KEYWORDS]: true,
    [RoutesConfig.PROFILE_SETTING]: true,
    [RoutesConfig.BUY_CREDITS]: true,
    [RoutesConfig.ADD_HIGHLIGHTED_CHANNELS]: true,
  };

  public static RouteToMenuItemMap: { [route: string]: number } = {
    [RoutesConfig.HOME]: ActiveMenuItem.HOME,
    [RoutesConfig.NEXLEV_DASHBOARD]: ActiveMenuItem.DASHBOARD,
    [RoutesConfig.KEYWORDS]: ActiveMenuItem.KEYWORDS,
    [RoutesConfig.CUSTOM_KEYWORDS]: ActiveMenuItem.CUSTOM_KEYWORDS,
    [RoutesConfig.CHANNELS]: ActiveMenuItem.CHANNELS,
    [RoutesConfig.SAVED]: ActiveMenuItem.SAVED,
    [RoutesConfig.RPM]: ActiveMenuItem.RPM_PREDICTOR,
    [RoutesConfig.CHATGPT]: ActiveMenuItem.CHATGPT,
  };

  public static getSidebarExpandedState = (route: string) =>
    this.sidebarExpandedStateConfig[route] ?? false;

  public static getMenuItem = (route: string) => this.RouteToMenuItemMap[route];
}

export default SidebarConfig;
