const routesConfig = {
  // public route
  LOGIN: '/login',
  REGISTRATION: '/registration',
  CONFIRM_USER: '/confirm-user',
  FORGOT_PASSWORD: '/forgot-pass',
  SET_NEW_PASSWORD: '/set-new-pass',

  // new user route
  SUBSCRIPTION: '/subscription',

  // private route
  HOME: '/',
  NEXLEV_DASHBOARD: '/nexlev-dashboard',
  KEYWORDS: '/keywords',
  KEYWORDS_TEMPLATE: '/keywords/filter-templates',
  CHANNELS: '/channels',
  CHANNELS_TEMPLATE: '/channels/filter-templates',
  HIGHLIGHTED_CHANNELS: '/highlighted-channels',
  ADD_HIGHLIGHTED_CHANNELS: '/highlighted-channels/add',
  RPM: '/rpm-predictor',
  CATEGORIES: '/select-category',
  SAVED: '/saved',
  BLACKLIST: '/blacklist',
  CHATGPT: '/nexlev-ai',
  PROFILE_SETTING: '/profile-setting',
  NEXLEV_ADMIN: '/nexlev-admin',
  CUSTOM_KEYWORDS: '/custom-keywords',
  BUY_CREDITS: '/buy-credits',
  PAYMENT_SUCCESS: '/payment/success',
  PAYMENT_FAILED: '/payment/failed',
};

export default routesConfig;
